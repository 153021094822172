import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import reserve from '../../api/rsv';
import Modal from '../Modal/Modal';

const RSVPFrom = () => {
  const [forms, setForms] = useState({
    fullName: '',
    email: '',
    assistance: 1,
    guest: 1,
    allergy: '',
  });
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [validator] = useState(
    new SimpleReactValidator({
      className: 'errorMessage',
      messages: {
        required: 'Questo campo è obbligatorio',
        email: 'Inserisci un indirizzo email valido',
        alpha_space: 'Questo campo non può contenere numeri',
        max: 'Il campo non può contenere più di 300 caratteri',
      },
    })
  );

  const changeHandler = (e) => {
    const { name, value, type } = e.target;
    const val = type === 'radio' ? parseInt(value) : value;
    setForms((prevForms) => ({
      ...prevForms,
      [name]: val,
    }));

    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      reserve({
        assistance: forms.assistance,
        name: forms.fullName,
        email: forms.email,
        members: forms.guest,
        notes: forms.allergy,
      }).then((response) => {
        setMessage(response.message);
        setOpen(true);
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <>
      <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active">
        <div className="row">
          <div className="col col-lg-12 col-12">
            <div className="form-field">
              <input
                value={forms.fullName}
                type="text"
                name="fullName"
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                className="form-control"
                placeholder="Nome e Cognome*"
              />
              {validator.message('name', forms.fullName, 'required|alpha_space')}
            </div>
          </div>
          <div className="col col-lg-12 col-12">
            <div className="form-field">
              <input
                value={forms.email}
                type="email"
                name="email"
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                className="form-control"
                placeholder="E-mail*"
              />
              {validator.message('email', forms.email, 'required|email')}
            </div>
          </div>
          <div className="col col-lg-12 col-12">
            <div className="radio-buttons">
              <p>
                <input
                  type="radio"
                  id="attend"
                  name="assistance"
                  value={1}
                  defaultChecked
                  onChange={(e) => changeHandler(e)}
                />
                <label htmlFor="attend">Si, ci sarò</label>
              </p>
              <p>
                <input
                  type="radio"
                  id="not"
                  name="assistance"
                  value={0}
                  onChange={(e) => changeHandler(e)}
                />
                <label htmlFor="not">No, non posso venire</label>
              </p>
            </div>
          </div>
          {forms.assistance === 1 ? (
            <>
              <div className="col col-lg-12 col-12">
                <div className="form-field">
                  <select
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                    value={forms.guest}
                    type="text"
                    className="form-control"
                    name="guest"
                  >
                    <option value={1}>Vengo da solo</option>
                    <option value={2}>Saremo in due</option>
                    <option value={3}>Saremo in tre</option>
                    <option value={4}>Saremo in quattro</option>
                    <option value={5}>Saremo in cinque</option>
                    <option value={6}>Saremo in sei</option>
                    <option value={7}>Saremo in sette</option>
                  </select>
                  {validator.message('guest', forms.guest, 'required')}
                </div>
              </div>
              <div className="col col-lg-12 col-12">
                <div className="form-field">
                  <input
                    value={forms.allergy}
                    type="text"
                    name="allergy"
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                    className="form-control"
                    placeholder="Eventuali intolleranze alimentari"
                  />
                  {validator.message('allergy', forms.allergy, 'alpha_space|max:300')}
                </div>
              </div>
            </>
          ) : (
            <div className="col col-lg-12 col-12">
              <div className="form-field">
                <input
                  value={forms.allergy}
                  type="text"
                  name="allergy"
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                  className="form-control"
                  placeholder="Commento"
                />
                {validator.message('allergy', forms.allergy, 'alpha_space|max:300')}
              </div>
            </div>
          )}
        </div>

        <div className="submit-area">
          <button type="submit" className="theme-btn">
            RSVP
          </button>
        </div>
      </form>
      {open && <Modal open={open} handleClose={() => setOpen(false)} body={message} />}
    </>
  );
};

export default RSVPFrom;
